import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_2/sub_3/slide1';

const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      Par1: mdx(
        frontmatter: { language: { eq: "EN" }, title: { eq: "slide-2-3-1__p1" } }
      ) {
        body
      }
      Par2: mdx(
        frontmatter: { language: { eq: "EN" }, title: { eq: "slide-2-3-1__p2" } }
      ) {
        body
      }
      Par3: mdx(
        frontmatter: { language: { eq: "EN" }, title: { eq: "slide-2-3-1__p3" } }
      ) {
        body
      }
      Map1: file(relativePath: { eq: "chapter_2/sub_3/mobile/1.png" }) {
        childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      Map2: file(relativePath: { eq: "chapter_2/sub_3/mobile/2.png" }) {
        childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      bg: file(relativePath: { eq: "chapter_2/sub_3/mobile/2-3-1-bg__mobile.png" }) {
        childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);
  return <Template query={query} title="Part III" />;
};

export default Slide;
